import React from "react"
import ImageMeta from "../components/ImageMeta"
import Layout from "../components/layout"
import Seamless from "../components/Seamless"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"

import TheaterVideo from "@components/Theater/TheaterVideo"
import Button from "@bit/azheng.joshua-tree.button"
import ButtonGroup from "@bit/azheng.joshua-tree.button-group"
import CTA from "../components/CTA/CTA"
import SEO from "../components/seo"
import { Link } from "gatsby"

import {
  Image,
  Video,
  Transformation,
  CloudinaryContext
} from "cloudinary-react"

const BackToBusinessPage = props => {
  const city_state = "British Columbia"
  const practice_name = "APEX Surgical Centre "
  // If no video consult, set design to two. Else, set to three.
  const design = "two"
  const biz_tel = "(250) 371-7076"
  const biz_tel_stripped = biz_tel.replace(/\D/g, "")
  // If no video consult, set let youtube to no-youtube. Else, set to any string.
  const youtube = "no-youtube"
  const youtube_code = ""
  const youtubeImageId = ""
  const form_url =
    "https://secureform.seamlessdocs.com/f/WWOSconlinevideoconsult"

  const iconColor = "333e48"

  return (
    <SharedStateProvider>
      <Layout>
        <SEO
          title={`We Are Open For Care in ${city_state}`}
          description={`We are excited to announced we are open for care in ${city_state}. Please call us to schedule an appointment with our practice.`}
        />
        <div className="joshua-tree-content">
          <div className="b2b-banner">
            <h1>WE ARE OPEN</h1>
          </div>
          <div className="b2b-top-section">
            <div className="columns">
              <div className="column is-5"></div>
              <div className="column">
                <p className="top-p">
                  <strong>IMPORTANT MESSAGE TO PATIENTS:</strong> If you or
                  someone in your household have a fever, cough, or flu-like
                  symptoms, please stay at home and don’t come to the practice.
                  Your doctor may request an online video consultation if you
                  are displaying symptoms.
                </p>
              </div>
              <div className="column is-5"></div>
            </div>

            <div className="treatment-grid">
              <div className="columns">
                <div className="column is-6"></div>
                <div className="treatment-column column">
                  <div className="icon-wrapper">
                    <img
                      src={`https://res.cloudinary.com/nuvolum/image/upload/c_scale,co_rgb:${iconColor},e_colorize:100,f_png/v1/Programs/doors-icon-blue.svg`}
                    />
                  </div>
                  <h3>
                    Our Doors Remain Open
                    <br />
                    For Our Community
                  </h3>
                  {/* <p>We are now scheduling appointments. If you need pediatric dental care, our team is here for&nbsp;you.</p> */}
                </div>
                <div className="column is-1"></div>
                {design === "three" && (
                  <>
                    <div className="teleconsult-box treatment-column column">
                      <a
                        className="teleconsult-link"
                        target="_blank"
                        href={form_url}
                      >
                        <div className="icon-wrapper">
                          <img
                            src={`https://res.cloudinary.com/nuvolum/image/upload/c_scale,co_rgb:${iconColor},e_colorize:100,f_png/v1/Programs/teleconsult-icon-blue.svg`}
                          />
                        </div>
                        <h3>
                          Offering Online Video
                          <br />
                          Appointments
                        </h3>
                        <p>
                          If you cannot visit the office, we are offering online
                          video consultations for you to be evaluated by
                          a&nbsp;specialist.
                        </p>

                        <div style={{ textAlign: "center" }}>
                          <a
                            target="_blank"
                            href={form_url}
                            className="b2b-button standard-button standalone has-text-centered"
                          >
                            Schedule Today
                          </a>
                        </div>
                      </a>
                    </div>
                    <div className="column is-1"></div>
                  </>
                )}
                <div className="treatment-column column">
                  <div className="icon-wrapper">
                    <img
                      src={`https://res.cloudinary.com/nuvolum/image/upload/c_scale,co_rgb:${iconColor},e_colorize:100,f_png/v1/Programs/safety-icon-blue.svg`}
                    />
                  </div>
                  <h3>
                    Committed to
                    <br />
                    Keeping You Safe
                  </h3>
                  <p>
                    We are taking additional measures to ensure the health and
                    safety of our patients and staff while our office
                    remains&nbsp;open.
                  </p>
                </div>
                <div className="column is-6"></div>
              </div>
            </div>
          </div>

          <section
            className={"body-section " + youtube}
            style={{ paddingBottom: 0 }}
          >
            <div className="columns">
              <div className="column is-18 is-offset-3">
                <TheaterVideo
                  videoUrl={`https://www.youtube.com/watch?v=${youtube_code}`}
                  controls={true}
                  playing={true}
                  overVideo={true}
                >
                  <ImageMeta
                    cloudName="nuvolum"
                    publicId={youtubeImageId}
                    width="auto"
                  ></ImageMeta>
                </TheaterVideo>
              </div>
            </div>
          </section>

          <section className="body-section">
            <div className="b2b-body wrapper columns">
              <div className="column is-5"></div>

              <div className="column">
                {/* <p className="large">
                  The entire {practice_name} team is excited to resume both
                  emergency and elective treatments again and fulfill your oral
                  surgery needs. We are scheduling appointments now.
                </p> */}

                <h5>Our Commitment to Health & Safety</h5>
                <p className="large">
                  As always, our practice takes every precaution to keep
                  patients and staff safe and healthy. While we have always
                  adhered to and exceeded the standards for decontamination set
                  by{" "}
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.ccohs.ca/"
                  >
                    CCOHS
                  </a>
                  , we are going one step further and incorporating the latest{" "}
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="http://covid-19.bccdc.ca/"
                  >
                    BCCDC
                  </a>{" "}
                  guidelines.
                </p>
                {/* <h5>The Measures We Are Taking To Protect Our Patients</h5> */}
                {/* <p className="large">
                  We ask you to keep the following in mind prior to and during
                  your appointment:
                </p> */}

                {/* <ul>
                  <li>
                    Patients are requested to fill out a Covid Self Assessment 3
                    days prior to their surgical visit, and answers will be
                    confirmed upon check-in. All patients and their guardians
                    will be provided with hand sanitizer and will be required to
                    wear masks while in the practice. All patients who will be
                    entering the clinical area must have their temperature taken
                    as well.
                  </li>
                </ul> */}

                <h5>How We Are Protecting the Safety of Our Staff & Doctors</h5>
                {/* <p className="large">
                  We are taking additional steps toward ensuring the safety of
                  our staff:
                </p> */}
                <ul>
                  <li>
                  All team members will follow strict personal protective equipment protocols and health checks. All surgical staff will be required to wear appropriate masks, goggles and/or face shields, hair nets, and gloves and our front reception area remains equipped with the protective plexiglass barriers.
                  </li>
                  <li>
                    Our team will continue to follow a strict decontamination
                    protocol.
                  </li>
                </ul>

                <p className="large">
                  At {practice_name} we appreciate the ongoing support from our
                  patients, referring providers, families, and friends. Thank
                  you for your patience and cooperation while these measures are
                  put into effect.
                </p>

                {/* <p className="large">
                  For questions or clarification about these measures, please
                  contact us at{" "}
                  <a href={`tel:+1${biz_tel_stripped}`}>{biz_tel}</a>.
                </p> */}
              </div>
              <div className="column is-5"></div>
            </div>

            <div className="b2b-cta wrapper columns">
              <div className="column is-5"></div>
              <div className="column">
                <h5>Schedule Your Appointment Today</h5>
                <p>Call our office for appointment enquiries.</p>

                <a
                  href={`tel:+1${biz_tel_stripped}`}
                  className="standard-button b2b-button contained"
                >
                  Call Us
                </a>
              </div>

              <div className="column is-5"></div>
            </div>
          </section>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export default BackToBusinessPage
